const $nav = document.querySelector('#header-top')
const threshold = $nav.getBoundingClientRect()
const thresholdMinus = threshold.top
const fixedClass = 'top-active'


let updating = false

const handleScroll = () => {
  if (window.scrollY <= thresholdMinus || window.pageYOffset <= thresholdMinus) {
    if (window.location.pathname === "/" || window.location.pathname === "/index.html") {
      $nav.classList.remove(fixedClass)
    } else {
      $nav.classList.add(fixedClass)
    }
  } else {
    $nav.classList.add(fixedClass)
  }
  updating = false
}
requestAnimationFrame(handleScroll)
window.onscroll = () => {
  if (updating) return
  else {
    updating = true
    requestAnimationFrame(handleScroll)
  }

  let elementId = ""
  document.querySelectorAll('[data-nav]').forEach((e, i) => elementId == "" ? (isVisible(e) ? elementId = e.getAttribute("data-nav") : elementId) : elementId)
  document.querySelectorAll('[data-target]').forEach(e => e.classList.remove("active"))
  document.querySelector(`[data-target="${elementId}"]`)?.classList?.add("active")
}

function isVisible(elm) {
  let rect = elm.getBoundingClientRect();
  let viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
  return !(rect.bottom <= 250 || rect.top - viewHeight >= 0);
}

let resizeSlider = false

window.addEventListener('resize', function (event) {
  if (window.matchMedia("(max-width: 992px)").matches) {
    resizeSlider = true
  }
}, true);


const swiper = new Swiper(".mySwiper", {
  direction: "vertical",
  loop: true,
  // autoplay: {
  //   delay: 5000,
  //   disableOnInteraction: false,
  //   pauseOnMouseEnter: true,
  // },
  grab: true,
  allowTouchMove: true,
  breakpoints: {
    320: {
      direction: "horizontal",
    },
    992: {
      direction: "horizontal",
    },
    1024: {
      direction: "vertical",
    }
  }
});


let lastScrollTop = 0;

window.addEventListener("scroll", function () {
  let st = window.pageYOffset || document.documentElement.scrollTop;
  let collapse = document.getElementById("navbarTogglerDemo03")
  if (st > lastScrollTop) {
    if (collapse?.classList?.contains("show")) {
      document.getElementById("header-top")?.classList?.remove("scroll-active")
    } else {
      document.getElementById("header-top")?.classList?.add("scroll-active")
    }

  } else if (st < lastScrollTop) {
    document.getElementById("header-top")?.classList?.remove("scroll-active")
  }
  lastScrollTop = st <= 0 ? 0 : st;


  if (lastScrollTop <= 30) {
    document.querySelector(".go-top").classList.remove("active")
    document.querySelector(".mode").classList.add("active")
  } else {
    document.querySelector(".go-top").classList.add("active")
    document.querySelector(".mode").classList.remove("active")
  }
}, false);


document.getElementById("current-year").innerHTML = new Date().getFullYear()

/* Language */

let storageLang = localStorage.getItem("lang") ? localStorage.getItem("lang") : localStorage.setItem("lang", "ru")

const changeLang = (langs) => {
  storageLang = langs
  localStorage.setItem("lang", langs)


  if (langs === "uz") {

  }
  if (langs === "ru") {

  }
  if (langs === "en") {

  }
}
document.querySelectorAll(".lang-text").forEach(item => {
  document.querySelectorAll(".lang-text").forEach(item => {
    if (storageLang === item.getAttribute("data-lang")) {
      item.classList.add("active")
      item.setAttribute("data-lang-active", "true")
    } else {
      item.classList.remove("active")
      item.setAttribute("data-lang-active", "false")
    }

  })

  item.addEventListener("click", () => {
    document.querySelectorAll(".lang-text").forEach(item => {
      item.classList.remove("active")
      item.setAttribute("data-lang-active", "false")
    })
    item.classList.add("active")
    item.setAttribute("data-lang-active", "true")

    document.querySelectorAll(".lang-text").forEach(item => {
      if (item.getAttribute("data-lang-active") === "true") {
        changeLang(item.getAttribute("data-lang"))
      }
    })

  })
})

changeLang(`${storageLang ?? "ru"}`)

document.querySelector("#form-contact")?.addEventListener("submit", (evt) => {
  evt.preventDefault()
  const tg_name = document.querySelector("#first_name").value
  const tg_email = document.querySelector("#email").value
  const tg_phone = document.querySelector("#phone").value
  const tg_subject = document.querySelector("#theme").value
  const tg_message = document.querySelector("#message").value

  if (tg_name.length < 3 || !tg_email || tg_phone.length < 9 || tg_subject < 3 || tg_message < 3) {
    return null
  } else {
    $?.ajax({
      url: "https://api.telegram.org/bot6404263006:AAF8NPnOR7qjoRA2PVK_la3zVSMX8qxuCJ0/sendMessage",
      type: "get", //send it through get method
      data: {
        chat_id: 569288138,
        text: "🔔Lifetime Stones Заявка🔔" + "\n" + "\n" + "\n" + "<b>Имя: </b>" + tg_name + "\n" + "<b>Электронная почта*: </b>" + tg_email + "\n" + "<b>Телефон*: </b>" + "\n" + tg_phone + "\n" + "<b>Тема*: </b>" + "\n" + tg_subject + "\n" + "<b>Сообщение*: </b>" + "\n" + tg_message,
        parse_mode: "html"
      },
      success: function (response) {
        document.getElementById("form-contact").reset();
        document.querySelector(".thx-text").classList.remove("d-none")

        setTimeout(() => {
          document.querySelector(".thx-text").classList.add("d-none")
        }, 3000)
        // document.getElementById("message_tg").reset();
        // document.getElementById("send_tg").reset();
        // document.getElementById("success").classList.remove("d-none")

      },
      error: function (xhr) {
        //Do Something to handle error
      }
    });
  }
})


let btn = document.querySelector(".mode"),
  btn_img = document.querySelector("#mode"),
  mode_theme = window.localStorage.getItem("dark");
btn?.addEventListener("click", () => {
  document.querySelector("html")?.toggleAttribute("data-dark");
  let dark = $("html").attr("data-dark") === "";
  btn_img.src = dark ? "images/moon.png" : "images/sun.png";
  window.localStorage.setItem("dark", dark ? "1" : "0")
});

if (["/", "/index.html"]) {
  document.querySelectorAll("[data-lang-name]").forEach(item => item.classList.remove("active"))
  document.querySelector("[data-lang-name='nav_1']").classList.add("active")
}
if (mode_theme === "1") {
  document.querySelector("html").setAttribute("data-dark", "");
  btn_img.src = "images/moon.png";
} else {
  document.querySelector("html").removeAttribute("data-dark");
  btn_img.src = "images/sun.png";
}
